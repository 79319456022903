@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    scroll-behavior: smooth;
    @apply font-roboto overflow-auto;
}

.imgFlecha{
    width: 35px !important;
}

html, 
body,
#App,
main {
    @apply h-full;
    background-color: #EDF2F7 !important;
}


.data-table-extensions {
    @apply my-5;
}

.data-table-extensions .data-table-extensions-filter .filter-text {
    @apply bg-gray-50 border border-gray-300 transition-all text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 max-w-md outline-none;
}

.rdt_Table .rdt_TableHeadRow {
    background: rgba(30, 58, 138, 1);
    @apply text-white text-xs font-medium uppercase;
}

.rdt_Table .rdt_TableBody .rdt_TableRow {
    @apply text-sm text-gray-900;
}
