
.login {
	@apply bg-dark-theme;
}

.login-input {
    @apply block w-full px-4 py-2 mt-2 text-dark font-normal bg-muted border-gray-500 focus:border-dark-theme focus:outline-none transition-colors duration-200 ease-in-out;
}

/*.password-input {
    @apply border-gray-500 border-y-2 border-l-2;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}*/

.show-password {
    @apply rounded-md text-white;
}

.error-message {
    @apply text-sm text-error font-normal mt-1;
}

.item-sidebar {
    @apply w-full flex items-center justify-start rounded-lg mt-2 transition-colors cursor-pointer hover:bg-orange-500 text-white text-base gap-x-4 py-2 px-5;
}

.logout-item {
	@apply hover:bg-error;
}

.rdt_Table,
.rdt_TableHead,
.rdt_TableRow {
    @apply text-base !important;
}

.rdt_Table {
    @apply border border-secondary;
}

.rdt_TableHeadRow {
    @apply bg-secondary text-white !important;
}

.rdt_TableHeadRow .rdt_TableCol,
.rdt_TableBody .rdt_TableRow .rdt_TableCell {
    @apply justify-center font-bold;
}

.rdt_TableHeadRow {
    @apply py-3;
}

.rdt_TableRow {
    @apply py-3;
}

.rdt_TableCell {
    @apply w-auto;
}

.rdt_TableRow:not(:last-of-type) {
    @apply border-b border-secondary !important;
}

.rdt_Pagination {
    @apply bg-transparent text-base border-none !important;
}

.toggle-bg:after {
    content: '';
    @apply absolute top-0.5 left-0.5 bg-white border border-gray-300 rounded-full h-5 w-5 transition shadow-sm;
}

input:checked + .toggle-bg:after {
    transform: translateX(100%);
    @apply border-white;
}

input:checked + .toggle-bg {
    @apply bg-theme border-theme;
}

.table-results .data-table-extensions-filter {
    @apply mb-4;
}

.table-results .data-table-extensions-filter .filter-text {
    @apply bg-white bg-opacity-50 rounded border border-gray-400 focus:border-orange-500 focus:bg-white focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50 text-base outline-none text-dark py-1 px-3 leading-8 transition-colors duration-200 ease-in-out;
}

.table-results .data-table-extensions-filter .filter-text::placeholder {
    @apply text-dark text-opacity-60;
}

.btn-report {
    @apply flex justify-between items-center bg-theme w-full px-5 py-4 rounded-lg lg:text-xl text-base text-center text-white font-bold transition-colors hover:bg-dark-theme;
}

.form-label {
    @apply leading-7 text-base text-dark font-semibold block mb-1;
}

.form-input {
    @apply w-full bg-gray-100 bg-opacity-50 rounded border border-gray-400 focus:border-orange-500 focus:bg-white focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50 text-base outline-none text-dark py-1 px-3 leading-8 transition-colors duration-200 ease-in-out;
}

.form-radio {
    @apply flex p-3 text-dark font-semibold transition-colors bg-white border border-gray-400 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-theme peer-checked:ring-2 peer-checked:bg-theme peer-checked:text-white peer-checked:border-theme;
}

.form-select {
    @apply w-full bg-gray-100 bg-opacity-50 rounded border border-gray-400 focus:border-orange-500 focus:bg-white focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50 text-base outline-none text-dark py-2 px-3 leading-8 transition-colors duration-200 ease-in-out;
}

.btn {
    @apply rounded-lg px-4 py-2 shadow-md font-bold text-white transition-colors sm:mt-0 sm:ml-0 sm:w-auto sm:text-base w-full inline-flex justify-center items-center border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}

/* .filter-text{
    @apply w-full;
} */


